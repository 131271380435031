<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Downloads
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
import security from '@/security'
export default {
  components: {
    KitsPanel: () =>
      import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {}
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        {
          icon: 'mdi-download',
          path: '/onexapp_install',
          title: 'Onexapp Install',
        },
        {
          icon: 'mdi-television-guide',
          path: '/dealer_guide',
          title: 'Dealer Guide',
        },
        {
          icon: 'mdi-human-male-board-poll',
          path: '/subscriber_guide',
          title: 'Subscriber Guide',
        },
        {
          icon: 'mdi-shopping',
          path: '/marketing_tool',
          title: 'Marketing Tools',
        },
        {
          icon: 'mdi-scale-balance',
          path: '/shariah_matter',
          title: 'Shariah Matter',
        },
        {
          icon: 'mdi-domain',
          path: '/business_registeration',
          title: 'Business Registration',
        },
        {
          icon: 'mdi-account-switch',
          path: '/ownership_transfer',
          title: 'Ownership Transfer',
        },
      ]
      security.me.claimer() === 'xox' &&
        menu.push({
          icon: 'mdi-file-document-outline',
          path: '/cp58_statement',
          title: 'CP58',
          desc: 'CP58 Statement',
        })
      return menu
    },
  },
}
</script>
